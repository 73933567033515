<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("dailyreporting.title") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-2 mb-3">
            <b-col cols="6">
              <h6 class="ssp-24">{{ $t("dailyreporting.title") }}</h6>
            </b-col>
            <b-col cols="6" class="text-right">
              <button class="btn btn-success btn-add mr-4" @click="onAdd">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                {{ $t("liq_reporting.add") }}
              </button>
            </b-col>
          </div>
          <div class="row table-utilities">
            <div class="row ml-2 w-100">
              <div class="col-md-10 search-section">
                <div class="input-group">
                  <div class="input-group-prepend bg-white">
                    <span class="input-group-text" id="basic-addon1">
                      <div class="font-12">
                        <i class="ri-search-line ri-lg ri-sub"></i>
                      </div>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control search-input"
                    :placeholder="$t('dailyreport.search')"
                    @input="setPage"
                    v-model="filterInput"
                  />
                </div>
              </div>
              <div class="col-md-2 pl-0">
                <button
                  class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
                  @click="handleExport"
                  :disabled="isLoading"
                >
                  <div class="font-12">
                    <div
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm text-success mr-1"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.export") }}
                </button>
              </div>
            </div>
            <div class="row w-100 ml-2 mt-2">
              <div class="col-md-2 pl-0">
                <b-button
                  class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
                  id="popover-button-filter"
                  href="#"
                  tabindex="0"
                >
                  <div class="font-12">
                    <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.filter") }}
                </b-button>
              </div>
              <!-- <div
                class="col-md-1-5"
                v-if="filterSelected1 !== null || filterSelected2 !== null"
              >
                <span
                  class="ssp-14-700 filter-button h-100 d-flex align-items-center px-2 mr-2"
                >
                  Tanggal &nbsp;
                  <span class="pointer" @click="resetFilter">&#10006;</span>
                </span>
              </div> -->
              <!-- <div class="col-md-2 pl-0">
                <div class="btn table-button ssp-14-700 filter-button h-100 d-flex align-items-center">
                  <div class="font-12">
                    <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
                  </div>
                  Date : {{  }}
                </div>
              </div> -->
              <b-popover
                placement="right"
                target="popover-button-filter"
                triggers="focus"
              >
                <b-tabs content-class="mt-2">
                  <b-tab title="Tanggal" active>
                    <div class="form-check multiple-row">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-today"
                        :value="filterDateTypes[0]"
                        @change="resetFilter1"
                        checked
                        v-model="filterDateType"
                      />
                      <label class="form-check-label" for="radio-today">
                        <div class="col">
                          <div class="row ssp-14">Today</div>
                          <div class="row">{{ today | formatDate2 }}</div>
                        </div>
                      </label>
                    </div>
                    <div class="form-check multiple-row">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-yesterday"
                        :value="filterDateTypes[1]"
                        @change="resetFilter1"
                        v-model="filterDateType"
                      />
                      <label class="form-check-label" for="radio-yesterday">
                        <div class="col">
                          <div class="row ssp-14">Yesterday</div>
                          <div class="row">{{ yesterday | formatDate2 }}</div>
                        </div>
                      </label>
                    </div>
                    <div class="form-check multiple-row">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-week"
                        :value="filterDateTypes[2]"
                        @change="setFilterSelectedWeek"
                        v-model="filterDateType"
                      />
                      <label class="form-check-label" for="radio-week">
                        <div class="col">
                          <div class="row ssp-14">This week</div>
                          <div class="row">
                            <span>{{
                              `${getDay(firstDayWeek)}&nbsp;-&nbsp;`
                            }}</span>
                            <span>{{ lastDayWeek | formatDate2 }}</span>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div class="form-check multiple-row">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-month"
                        :value="filterDateTypes[3]"
                        @change="setFilterSelectedMonth"
                        v-model="filterDateType"
                      />
                      <label class="form-check-label" for="radio-month">
                        <div class="col">
                          <div class="row ssp-14">This month</div>
                          <div class="row">
                            <span>{{
                              `${getDay(firstDayMonth)}&nbsp;-&nbsp;`
                            }}</span>
                            <span>{{ lastDayMonth | formatDate2 }}</span>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div class="form-check mb-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-custom"
                        :value="filterDateTypes[4]"
                        v-model="filterDateType"
                      />
                      <label class="form-check-label ssp-14" for="radio-custom">
                        Custom Range
                      </label>
                    </div>
                    <b-row
                      v-if="filterDateType == filterDateTypes[4]"
                      class="mb-2"
                    >
                      <b-col cols="6" class="pr-1">
                        <span>FROM</span>
                        <input
                          type="date"
                          class="form-control float-right"
                          v-model="filterSelected1"
                        />
                      </b-col>
                      <b-col cols="6" class="pl-1">
                        <span>TO</span>
                        <input
                          type="date"
                          class="form-control"
                          v-model="filterSelected2"
                        />
                      </b-col>
                    </b-row>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 ssp-14"
                      :class="{ disabled: filterDateType === null }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                  <b-tab title="Kegiatan">
                    <div class="filter-overflow">
                      <div
                        class="form-checkbox"
                        v-for="kegiatan in typeKegiatanOptions"
                        :key="kegiatan.id"
                      >
                        <input
                          :id="kegiatan.value"
                          class="form-radio-item"
                          type="radio"
                          :value="kegiatan.value"
                          v-model="filterKegiatan"
                        />
                        <label class="form-radio-label" :for="kegiatan.value">
                          {{ kegiatan.label }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterKegiatan === null }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                  <!-- <b-tab title="Retail/Distri">
                    <p>{{ $t("dailyreport.label.vr") }}/Distri</p>
                  </b-tab> -->
                </b-tabs>
              </b-popover>
            </div>
          </div>
          <div class="table-borderless mx-3">
            <b-table-simple hover responsive>
              <b-thead class="bv-head">
                <b-tr>
                  <b-th
                    ><input
                      type="checkbox"
                      @click="selectAll"
                      v-model="allSelected"
                  /></b-th>
                  <b-th v-for="hd in $t('dailyreporting.headers')" :key="hd.id">
                    {{ hd }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody v-if="loading">
                <table-spinner cs="5" />
              </b-tbody>
              <b-tbody v-if="this.dataLaporan.length !== 0">
                <b-tr
                  class="ssp-16-400"
                  v-for="laporan in dataLaporan"
                  :key="laporan.id"
                >
                  <b-td
                    ><input
                      type="checkbox"
                      :value="laporan.id"
                      v-model="lapIDs"
                      @click="select"
                  /></b-td>
                  <b-td class="daily-1">{{
                    laporan.tanggal | formatDate2
                  }}</b-td>
                  <b-td class="daily-2">{{
                    convertKegiatan(laporan.kegiatan)
                  }}</b-td>
                  <b-td v-if="laporan.hd1 !== null" class="daily-3">{{
                    laporan.hd1
                  }}</b-td>
                  <b-td v-if="laporan.hr1 !== null" class="daily-3">{{
                    laporan.hr1
                  }}</b-td>
                  <b-td v-if="laporan.hp1 !== null" class="daily-3">{{
                    laporan.hp1
                  }}</b-td>
                  <b-td v-if="laporan.hasil_kebun !== null" class="daily-3">{{
                    laporan.hasil_kebun
                  }}</b-td>
                  <b-td
                    v-if="laporan.hasil_marketing !== null"
                    class="daily-3"
                    >{{ laporan.hasil_marketing }}</b-td
                  >
                  <b-td
                    v-if="laporan.hasil_administrasi !== null"
                    class="daily-3"
                    >{{ laporan.hasil_administrasi }}
                  </b-td>
                  <b-td class="action-column">
                    <div class="action-wrapper font-12">
                      <i
                        class="ri-eye-fill ri-lg ri-mid action-btn"
                        @click="onDetail(laporan)"
                      ></i>
                      <i
                        class="ri-edit-fill ri-lg ri-mid action-btn"
                        @click="onEdit(laporan)"
                      ></i>
                      <!-- <i class="ri-delete-back-2-fill ri-lg ri-mid action-btn" @click="onDelete(laporan.id)"></i> -->
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tbody v-else class="text-center text-italic">
                <b-tr>
                  <b-td colspan="10">{{ $t("global.empty") }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <div class="row align-items-center table-footer">
              <div class="col-sm-6 ssp-14-700">
                {{ $t("global.total_data") }} {{ this.totalData }}
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-7 align-self-center page-text">
                    {{ $t("global.page_on") }}
                  </div>
                  <div class="col float-right">
                    <div class="row">
                      <select
                        class="form-control pagination-pg"
                        v-model="currPage"
                      >
                        <option
                          v-for="index in totalPages"
                          :key="index.id"
                          :value="index"
                        >
                          {{ index++ }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col page-arrow ssp-14-700 ml-2">
                    <div class="row float-right">
                      <div
                        @click="redcPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div class="font-12" :class="{ orange: currPage > 1 }">
                          <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                      <div
                        @click="addPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div
                          class="font-12"
                          :class="{ orange: currPage < totalPages }"
                        >
                          <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <b-modal id="modal-laporan" hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">
          <img
            class="mr-2"
            src="@/assets/img/tbhdata-form.png"
            alt="FORM"
            style="height: 1.8rem;"
          />
          {{ modalTitle }}
        </h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <!-- <p class="">Modal Body with button</p> -->
        <form @submit="onSubmit">
          <div class="form-group">
            <label for="tanggal-laporan" class="ssp-16 text-black">{{
              $t("dailyreport.label.date")
            }}</label>
            <datepicker
              v-if="!isDisabled"
              input-class="form-control datepicker"
              :class="{
                'invalid-form rounded-input': $v.formTambah.tanggal.$error
              }"
              v-model="$v.formTambah.tanggal.$model"
              :disabled-dates="disableDates"
              :use-utc="true"
              :placeholder="$t('dailyreport.placeholder.date')"
            />
            <div class="text-sm text-red" v-if="$v.formTambah.tanggal.$error">
              <div>{{ $t("feedback.required") }}</div>
            </div>
            <datepicker
              v-if="isDisabled"
              input-class="form-control"
              v-model="formTambah.tanggal"
              :disabled="true"
            />
          </div>
          <div class="form-group" v-if="!isDetail">
            <label for="nama-produk" class="ssp-16 text-black"
              >{{ $t("dailyreport.label.date") }}
              <span class="text-red"> *</span>
            </label>
            <custom-select
              :placeholder="$t('dailyreport.placeholder.type')"
              :allow-empty="false"
              :class="{ 'invalid-form rounded-input': $v.fieldKeg.$error }"
              v-model="$v.fieldKeg.$model"
              :options="typeKegiatanOptions.map(keg => keg.value)"
              @select="resetKeg"
              :custom-label="
                opt => typeKegiatanOptions.find(x => x.value == opt).label
              "
              deselectLabel=" "
            >
            </custom-select>
            <div class="text-sm text-red" v-if="$v.fieldKeg.$error">
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>

          <section v-if="fieldKeg === 'VD'">
            <div class="form-group">
              <label for="distributor" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vd") }} 1</label
              >
              <input
                v-if="isDetail"
                type="text"
                class="form-control"
                v-model="$v.formTambah.distributor1.$model"
                :disabled="true"
              />
              <custom-select
                v-else
                :placeholder="$t('liq_reporting.placeholder.dist')"
                :class="{
                  'invalid-form rounded-input':
                    $v.formTambah.distributor1.$error
                }"
                v-model="$v.formTambah.distributor1.$model"
                :options="dataDistributor.map(dist => dist.id)"
                :custom-label="
                  opt => `${dataDistributor.find(x => x.id == opt).name} 
                  (${dataDistributor.find(x => x.id == opt).owner})`
                "
                :disabled="isDisabled"
              >
              </custom-select>
              <div
                class="text-sm text-red"
                v-if="$v.formTambah.distributor1.$error"
              >
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
            <div class="form-group mb-2">
              <label for="hd2" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_distributor1.$error }"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_distributor1.$model"
                :disabled="isDisabled"
              ></textarea>
              <div
                class="text-sm text-red"
                v-if="$v.formTambah.h_distributor1.$error"
              >
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
            <div v-if="expandDist" class="form-group mt-4">
              <label for="distributor" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vd") }} 2</label
              >
              <input
                v-if="isDetail"
                type="text"
                class="form-control"
                v-model="$v.formTambah.distributor2.$model"
                :disabled="true"
              />
              <custom-select
                v-else
                :placeholder="$t('liq_reporting.placeholder.dist')"
                :class="{
                  'invalid-form rounded-input':
                    $v.formTambah.distributor2.$error
                }"
                v-model="$v.formTambah.distributor2.$model"
                :options="dataDistributor.map(dist => dist.id)"
                :custom-label="
                  opt => `${dataDistributor.find(x => x.id == opt).name} 
                  (${dataDistributor.find(x => x.id == opt).owner})`
                "
                :disabled="isDisabled"
              >
              </custom-select>
              <div
                class="text-sm text-red"
                v-if="$v.formTambah.distributor2.$error"
              >
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
            <div v-if="expandDist" class="form-group">
              <label for="hd2" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_distributor2.$error }"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_distributor2.$model"
                :disabled="isDisabled"
              ></textarea>
              <div
                class="text-sm text-red"
                v-if="$v.formTambah.h_distributor2.$error"
              >
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
            <button
              v-if="!expandDist && !isDetail"
              type="button"
              class="btn btn-success-light mb-2"
              @click="expandDist = true"
              style="font-size: 14px; border-radius: 8px"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16"
                height="16"
                style="vertical-align: sub;"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                  fill="rgba(30,126,52,1)"
                />
              </svg>
              {{ $t("distributors.add") }}
            </button>
          </section>

          <section v-if="fieldKeg === 'VR1'">
            <div class="form-group">
              <label for="retailer1" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vr") }} R1 1</label
              >
              <input
                v-if="isDetail"
                type="text"
                class="form-control"
                v-model="$v.formTambah.retailer1.$model"
                :disabled="true"
              />
              <custom-select
                v-else
                :placeholder="$t('dailyreport.placeholder.vr')"
                :class="{
                  'invalid-form rounded-input': $v.formTambah.retailer1.$error
                }"
                v-model="$v.formTambah.retailer1.$model"
                :options="dataR1.map(dist => dist.id)"
                :custom-label="
                  opt =>
                    `${dataR1.find(x => x.id == opt).name} 
                  (${dataR1.find(x => x.id == opt).owner})`
                "
                :disabled="isDisabled"
              >
              </custom-select>
              <div
                class="text-sm text-red"
                v-if="$v.formTambah.retailer1.$error"
              >
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
            <div class="form-group mb-2">
              <label for="hr1" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_retailer1.$error }"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_retailer1.$model"
                :disabled="isDisabled"
              ></textarea>
              <div
                class="text-sm text-red"
                v-if="$v.formTambah.h_retailer1.$error"
              >
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
            <div v-if="expandRet1" class="form-group mt-4">
              <label for="retailer2" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vr") }} R1 2</label
              >
              <input
                v-if="isDetail"
                type="text"
                class="form-control"
                v-model="$v.formTambah.retailer2.$model"
                :disabled="true"
              />
              <custom-select
                v-else
                :placeholder="$t('dailyreport.placeholder.vr')"
                :class="{
                  'invalid-form rounded-input': $v.formTambah.retailer2.$error
                }"
                v-model="$v.formTambah.retailer2.$model"
                :options="dataR1.map(dist => dist.id)"
                :custom-label="
                  opt =>
                    `${dataR1.find(x => x.id == opt).name} 
                  (${dataR1.find(x => x.id == opt).owner})`
                "
                :disabled="isDisabled"
              >
              </custom-select>
              <div
                class="text-sm text-red"
                v-if="$v.formTambah.retailer2.$error"
              >
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
            <div v-if="expandRet1" class="form-group mb-2">
              <label for="hr1" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_retailer2.$error }"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_retailer2.$model"
                :disabled="isDisabled"
              ></textarea>
              <div
                class="text-sm text-red"
                v-if="$v.formTambah.h_retailer2.$error"
              >
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
            <div v-if="expandRet2" class="form-group mt-4">
              <label for="retailer3" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vr") }} R1 3</label
              >
              <input
                v-if="isDetail"
                type="text"
                class="form-control"
                v-model="$v.formTambah.retailer3.$model"
                :disabled="true"
              />
              <custom-select
                v-else
                :placeholder="$t('dailyreport.placeholder.vr')"
                :class="{
                  'invalid-form rounded-input': $v.formTambah.retailer3.$error
                }"
                v-model="$v.formTambah.retailer3.$model"
                :options="dataR1.map(dist => dist.id)"
                :custom-label="
                  opt =>
                    `${dataR1.find(x => x.id == opt).name} 
                  (${dataR1.find(x => x.id == opt).owner})`
                "
                :disabled="isDisabled"
              >
              </custom-select>
              <div
                class="text-sm text-red"
                v-if="$v.formTambah.retailer3.$error"
              >
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
            <div v-if="expandRet2" class="form-group mb-2">
              <label for="hr1" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_retailer3.$error }"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_retailer3.$model"
                :disabled="isDisabled"
              ></textarea>
              <div
                class="text-sm text-red"
                v-if="$v.formTambah.h_retailer3.$error"
              >
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
            <button
              v-if="!expandRet1 && !isDetail"
              type="button"
              class="btn btn-success-light mb-2"
              @click="expandRet1 = true"
              style="font-size: 14px; border-radius: 8px"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16"
                height="16"
                style="vertical-align: sub;"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                  fill="rgba(30,126,52,1)"
                />
              </svg>
              Tambah Retailer
            </button>
            <button
              v-if="!expandRet2 && expandRet1 && !isDetail"
              type="button"
              class="btn btn-success-light mb-2"
              @click="expandRet2 = true"
              style="font-size: 14px; border-radius: 8px"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16"
                height="16"
                style="vertical-align: sub;"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                  fill="rgba(30,126,52,1)"
                />
              </svg>
              Tambah Retailer
            </button>
          </section>

          <section v-if="fieldKeg === 'VR2'">
            <div class="form-group">
              <label for="retailer1" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vr") }} R2 1</label
              >
              <input
                v-if="isDetail"
                type="text"
                class="form-control"
                v-model="$v.formTambah.retailer1.$model"
                :disabled="true"
              />
              <custom-select
                v-else
                :placeholder="$t('dailyreport.placeholder.vr')"
                :class="{
                  'invalid-form rounded-input': $v.formTambah.retailer1.$error
                }"
                v-model="$v.formTambah.retailer1.$model"
                :options="dataR2.map(dist => dist.id)"
                :custom-label="
                  opt =>
                    `${dataR2.find(x => x.id == opt).name} 
                  (${dataR2.find(x => x.id == opt).owner})`
                "
                :disabled="isDisabled"
              >
              </custom-select>
              <div
                class="text-sm text-red"
                v-if="$v.formTambah.retailer1.$error"
              >
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
            <div class="form-group mb-2">
              <label for="hr1" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_retailer1.$error }"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_retailer1.$model"
                :disabled="isDisabled"
              ></textarea>
              <div
                class="text-sm text-red"
                v-if="$v.formTambah.h_retailer1.$error"
              >
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
            <div v-if="expandRet1" class="form-group mt-4">
              <label for="retailer2" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vr") }} R2 2</label
              >
              <input
                v-if="isDetail"
                type="text"
                class="form-control"
                v-model="$v.formTambah.retailer2.$model"
                :disabled="true"
              />
              <custom-select
                v-else
                :placeholder="$t('dailyreport.placeholder.vr')"
                :class="{
                  'invalid-form rounded-input': $v.formTambah.retailer2.$error
                }"
                v-model="$v.formTambah.retailer2.$model"
                :options="dataR2.map(dist => dist.id)"
                :custom-label="
                  opt =>
                    `${dataR2.find(x => x.id == opt).name} 
                  (${dataR2.find(x => x.id == opt).owner})`
                "
                :disabled="isDisabled"
              >
              </custom-select>
              <div
                class="text-sm text-red"
                v-if="$v.formTambah.retailer2.$error"
              >
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
            <div v-if="expandRet1" class="form-group">
              <label for="hr1" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_retailer2.$error }"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_retailer2.$model"
                :disabled="isDisabled"
              ></textarea>
              <div
                class="text-sm text-red"
                v-if="$v.formTambah.h_retailer2.$error"
              >
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
            <div v-if="expandRet2" class="form-group mt-4">
              <label for="retailer3" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.vr") }} R2 3</label
              >
              <input
                v-if="isDetail"
                type="text"
                class="form-control"
                v-model="$v.formTambah.retailer3.$model"
                :disabled="true"
              />
              <custom-select
                v-else
                :placeholder="$t('dailyreport.placeholder.vr')"
                :class="{
                  'invalid-form rounded-input': $v.formTambah.retailer3.$error
                }"
                v-model="$v.formTambah.retailer3.$model"
                :options="dataR2.map(dist => dist.id)"
                :custom-label="
                  opt =>
                    `${dataR2.find(x => x.id == opt).name} 
                  (${dataR2.find(x => x.id == opt).owner})`
                "
                :disabled="isDisabled"
              >
              </custom-select>
              <div
                class="text-sm text-red"
                v-if="$v.formTambah.retailer3.$error"
              >
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
            <div v-if="expandRet2" class="form-group mb-2">
              <label for="hr1" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_retailer3.$error }"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_retailer3.$model"
                :disabled="isDisabled"
              ></textarea>
              <div
                class="text-sm text-red"
                v-if="$v.formTambah.h_retailer3.$error"
              >
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
            <button
              v-if="!expandRet1 && !isDetail"
              type="button"
              class="btn btn-success-light mb-2"
              @click="expandRet1 = true"
              style="font-size: 14px; border-radius: 8px"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16"
                height="16"
                style="vertical-align: sub;"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                  fill="rgba(30,126,52,1)"
                />
              </svg>
              Tambah Retailer
            </button>
            <button
              v-if="!expandRet2 && expandRet1 && !isDetail"
              type="button"
              class="btn btn-success-light mb-2"
              @click="expandRet2 = true"
              style="font-size: 14px; border-radius: 8px"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16"
                height="16"
                style="vertical-align: sub;"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                  fill="rgba(30,126,52,1)"
                />
              </svg>
              Tambah Retailer
            </button>
          </section>

          <section v-if="fieldKeg === 'VF'">
            <div class="form-group">
              <label for="farmer1" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.farmer") }} 1</label
              >
              <input
                v-if="isDetail"
                type="text"
                class="form-control"
                v-model="$v.formTambah.farmer1.$model"
                :disabled="true"
              />
              <custom-select
                v-else
                :placeholder="$('dailyreport.placeholder.farmer')"
                :class="{
                  'invalid-form rounded-input': $v.formTambah.farmer1.$error
                }"
                v-model="$v.formTambah.farmer1.$model"
                :options="dataFarmer.map(dist => dist.id)"
                :custom-label="opt => dataFarmer.find(x => x.id == opt).name"
                :disabled="isDisabled"
              >
              </custom-select>
              <div class="text-sm text-red" v-if="$v.formTambah.farmer1.$error">
                <div>Nama farmer boleh kosong</div>
              </div>
            </div>
            <div class="form-group mb-2">
              <label for="hr1" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_farmer1.$error }"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_farmer1.$model"
                :disabled="isDisabled"
              ></textarea>
              <div
                class="text-sm text-red"
                v-if="$v.formTambah.h_farmer1.$error"
              >
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
            <div v-if="expandFarm" class="form-group mt-4">
              <label for="farmer2" class="ssp-16 text-black"
                >{{ $t("dailyreport.label.farmer") }} 2</label
              >
              <input
                v-if="isDetail"
                type="text"
                class="form-control"
                v-model="$v.formTambah.farmer2.$model"
                :disabled="true"
              />
              <custom-select
                v-else
                :placeholder="$('dailyreport.placeholder.farmer')"
                :class="{
                  'invalid-form rounded-input': $v.formTambah.farmer2.$error
                }"
                v-model="$v.formTambah.farmer2.$model"
                :options="dataFarmer.map(dist => dist.id)"
                :custom-label="opt => dataFarmer.find(x => x.id == opt).name"
                :disabled="isDisabled"
              >
              </custom-select>
              <div class="text-sm text-red" v-if="$v.formTambah.farmer2.$error">
                <div>Nama farmer boleh kosong</div>
              </div>
            </div>
            <div v-if="expandFarm" class="form-group mb-2">
              <label for="hr1" class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_farmer2.$error }"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_farmer2.$model"
                :disabled="isDisabled"
              ></textarea>
              <div
                class="text-sm text-red"
                v-if="$v.formTambah.h_farmer2.$error"
              >
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
            <button
              v-if="!expandFarm && !isDetail"
              type="button"
              class="btn btn-success-light mb-2"
              @click="expandFarm = true"
              style="font-size: 14px; border-radius: 8px"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16"
                height="16"
                style="vertical-align: sub;"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                  fill="rgba(30,126,52,1)"
                />
              </svg>
              Tambah Farmer
            </button>
          </section>

          <section v-if="fieldKeg === 'VK'">
            <div class="form-group">
              <label for="nama-kebun" class="ssp-16 text-black">{{
                $t("dailyreport.label.vk")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="nama-kebun"
                placeholder="Masukkan nama kebun"
                :class="{ 'invalid-form': $v.formTambah.kebun.$error }"
                v-model="$v.formTambah.kebun.$model"
                :disabled="isDisabled"
              />
              <div class="text-sm text-red" v-if="$v.formTambah.kebun.$error">
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
            <label class="ssp-16 text-black">{{
              $t("dailyreport.label.result")
            }}</label>
            <textarea
              class="form-control no-resize"
              :class="{ 'invalid-form': $v.formTambah.h_kebun.$error }"
              id="lokasi-kegiatan"
              cols="30"
              rows="5"
              :placeholder="$t('dailyreport.placeholder.result')"
              v-model="$v.formTambah.h_kebun.$model"
              :disabled="isDisabled"
            ></textarea>
            <div class="text-sm text-red" v-if="$v.formTambah.h_kebun.$error">
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </section>

          <section v-if="fieldKeg === 'MA'">
            <div v-if="fieldKeg === 'MA'" class="form-group">
              <label for="nama-kegmr" class="ssp-16 text-black">{{
                $t("dailyreport.label.ma")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="nama-kegmr"
                placeholder="Masukkan nama kegiatan Marketing"
                :class="{ 'invalid-form': $v.formTambah.marketing.$error }"
                v-model="$v.formTambah.marketing.$model"
                :disabled="isDisabled"
              />
              <div
                class="text-sm text-red"
                v-if="$v.formTambah.marketing.$error"
              >
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
            <label class="ssp-16 text-black">{{
              $t("dailyreport.label.result")
            }}</label>
            <textarea
              class="form-control no-resize"
              :class="{ 'invalid-form': $v.formTambah.h_marketing.$error }"
              id="lokasi-kegiatan"
              cols="30"
              rows="5"
              :placeholder="$t('dailyreport.placeholder.result')"
              v-model="$v.formTambah.h_marketing.$model"
              :disabled="isDisabled"
            ></textarea>
            <div
              class="text-sm text-red"
              v-if="$v.formTambah.h_marketing.$error"
            >
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </section>

          <section v-if="fieldKeg === 'ADM'">
            <div v-if="fieldKeg === 'ADM'" class="form-group">
              <label for="nama-adm" class="ssp-16 text-black">{{
                $t("dailyreport.label.adm")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="nama-adm"
                placeholder="Masukkan Administrasi"
                :class="{ 'invalid-form': $v.formTambah.administ.$error }"
                v-model="$v.formTambah.administ.$model"
                :disabled="isDisabled"
              />
              <div
                class="text-sm text-red"
                v-if="$v.formTambah.administ.$error"
              >
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
            <div v-if="fieldKeg === 'ADM'" class="form-group">
              <label class="ssp-16 text-black">{{
                $t("dailyreport.label.result")
              }}</label>
              <textarea
                class="form-control no-resize"
                :class="{ 'invalid-form': $v.formTambah.h_administ.$error }"
                id="lokasi-kegiatan"
                cols="30"
                rows="5"
                :placeholder="$t('dailyreport.placeholder.result')"
                v-model="$v.formTambah.h_administ.$model"
                :disabled="isDisabled"
              ></textarea>
              <div
                class="text-sm text-red"
                v-if="$v.formTambah.h_administ.$error"
              >
                <div>{{ $t("feedback.required") }}</div>
              </div>
            </div>
          </section>

          <div class="form-group mt-3">
            <label class="ssp-16 text-black">
              {{ $t("dailyreport.label.doc") }}
              <span v-if="!isDetail" class="text-red">*</span>
              <span v-if="isEdit" class="small">
                <br />{{ $t("liq_reporting.placeholder.inv_change") }}</span
              >
            </label>
            <b-form-file
              v-if="!isDetail"
              v-model="$v.dokumentasiFile.$model"
              accept=".jpg, .png, .jpeg"
              :class="{
                'invalid-form rounded-input': $v.dokumentasiFile.$error
              }"
              @input="handleFileUploads"
              :placeholder="$t('global.excel_pch')"
              drop-placeholder="Drop file here..."
            >
            </b-form-file>
            <div class="row" v-if="isEdit || isDetail">
              <div class="col-md-10 offset-1 mt-3">
                <img
                  class="d-block ml-auto mr-auto"
                  v-if="formTambah.dokumentasi != null"
                  :src="url + formTambah.dokumentasi"
                  :alt="formTambah.dokumentasi"
                />
                <div v-else class="mx-auto text-center">
                  {{ $t("feedback.dnot_avb") }}
                </div>
              </div>
            </div>
            <!-- <div class="text-sm text-red" v-if="$v.formTambah.dokumentasi.$error">
              <div>{{ $t("dailyreport.label.doc") }} tidak boleh kosong</div>
            </div> -->
          </div>
          <button
            v-if="!isDetail"
            type="submit"
            class="btn btn-primary ssp-14 w-100 my-3 py-2"
            :class="{ disabled: loading }"
          >
            <div v-if="loading" class="spinner mr-2 d-inline">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
            <span class="d-inline">
              {{ $t("global.submit_btn") }}
            </span>
          </button>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { Card } from "@/components/index";
import Multiselect from "vue-multiselect";

import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
import { required, requiredIf } from "vuelidate/lib/validators";
import { M_ADD_DAILYREPORT, M_EDIT_DAILYREPORT } from "@/graphql/tss";
import {
  Q_LIST_DAILYREPORT,
  Q_LIST_DISTRIBUTOR,
  Q_LIST_RETAILER,
  Q_LIST_FARMER
} from "@/graphql/queries";
import {
  TypeKegiatan,
  RetailerType,
  RoleType,
  filterDateType
} from "@/graphql/enum.type.js";
import JwtService from "@/core/services/jwt.service";
import Axios from "axios";

export default {
  components: {
    Card,
    Datepicker,
    "custom-select": Multiselect
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      dataLaporan: [],
      detailUser: JwtService.getDetail(),
      countryID: [],
      regionID: [],
      provID: [],

      totalPages: null,
      currPage: 1,
      perPage: 10,
      totalData: null,
      filterInput: "",
      filterKegiatan: "",
      show_filter: false,
      customFilter: false,
      filterSelected1: "",
      filterSelected2: "",
      filterDateType: null,

      disableDates: {
        to: new Date(new Date() - 86400000 * 3),
        from: new Date(new Date() + 86400000)
      },

      today: moment(new Date(new Date().setHours(0, 0, 0, 0))).format(
        "YYYY-MM-DD"
      ),
      yesterday: moment(
        new Date(new Date().setHours(0, 0, 0, 0) - 86400000)
      ).format("YYYY-MM-DD"),
      firstDayWeek: moment()
        .startOf("week")
        .toDate(),
      lastDayWeek: moment()
        .endOf("week")
        .toDate(),
      firstDayMonth: null,
      lastDayMonth: null,

      lapIDs: [],
      selected: [],
      allSelected: false,

      dataProduk: [],
      dataR1: [],
      dataR2: [],
      dataDistributor: [],
      dataFarmer: [],

      typeKegiatanOptions: [
        TypeKegiatan.VD,
        TypeKegiatan.VR1,
        TypeKegiatan.VR2,
        TypeKegiatan.VF,
        TypeKegiatan.VK,
        TypeKegiatan.MA,
        TypeKegiatan.ADM
      ],
      retailerTypeOptions: [RetailerType.R1, RetailerType.R2],
      filterDateTypes: [
        filterDateType.TODAY,
        filterDateType.YESTERDAY,
        filterDateType.THIS_WEEK,
        filterDateType.THIS_MONTH,
        filterDateType.CUSTOM_DATE
      ],

      roleOptions: RoleType.AM,
      modalTitle: "",
      isEdit: false,
      isDisabled: false,
      isDetail: false,
      url: JwtService.getURL(),

      // invoiceFile: [], // Store our uploaded files
      retailerSelected: "",

      // fieldKeg: {
      //   d1: null,
      //   r1: null,
      //   r2: null,
      //   f1: null,
      //   kb: null,
      //   mkt: null,
      //   adm: null,
      // },
      fieldKeg: null,
      dokumentasiFile: null,
      dokumentasiName: null,
      dokumentasiURL: null,
      expandDist: false,
      expandRet1: false,
      expandRet2: false,
      expandFarm: false,
      formTambah: {
        id: "",
        role: "",
        tanggal: null,
        distributor1: null,
        h_distributor1: null,
        distributor2: null,
        h_distributor2: null,
        retailer1: null,
        h_retailer1: null,
        retailer2: null,
        h_retailer2: null,
        retailer3: null,
        h_retailer3: null,
        farmer1: null,
        h_farmer1: null,
        farmer2: null,
        h_farmer2: null,
        kebun: null,
        h_kebun: null,
        marketing: null,
        h_marketing: null,
        administ: null,
        h_administ: null,
        dokumentasi: null
      }
    };
  },
  validations: {
    fieldKeg: { required },
    formTambah: {
      tanggal: { required },
      distributor1: {
        required: requiredIf(function() {
          return this.fieldKeg === "VD";
        })
      },
      h_distributor1: {
        required: requiredIf(function() {
          return this.fieldKeg === "VD";
        })
      },
      distributor2: {
        required: requiredIf(function() {
          return this.fieldKeg === "VD" && this.expandDist;
        })
      },
      h_distributor2: {
        required: requiredIf(function() {
          return this.fieldKeg === "VD" && this.expandDist;
        })
      },
      retailer1: {
        required: requiredIf(function() {
          return this.fieldKeg === "VR1" || this.fieldKeg === "VR2";
        })
      },
      h_retailer1: {
        required: requiredIf(function() {
          return this.fieldKeg === "VR1" || this.fieldKeg === "VR2";
        })
      },
      retailer2: {
        required: requiredIf(function() {
          return (
            (this.fieldKeg === "VR1" || this.fieldKeg === "VR2") &&
            this.expandRet1
          );
        })
      },
      h_retailer2: {
        required: requiredIf(function() {
          return (
            (this.fieldKeg === "VR1" || this.fieldKeg === "VR2") &&
            this.expandRet1
          );
        })
      },
      retailer3: {
        required: requiredIf(function() {
          return (
            (this.fieldKeg === "VR1" || this.fieldKeg === "VR2") &&
            this.expandRet2
          );
        })
      },
      h_retailer3: {
        required: requiredIf(function() {
          return (
            (this.fieldKeg === "VR1" || this.fieldKeg === "VR2") &&
            this.expandRet2
          );
        })
      },
      farmer1: {
        required: requiredIf(function() {
          return this.fieldKeg === "VF";
        })
      },
      h_farmer1: {
        required: requiredIf(function() {
          return this.fieldKeg === "VF";
        })
      },
      farmer2: {
        required: requiredIf(function() {
          return this.fieldKeg === "VF" && this.expandFarm;
        })
      },
      h_farmer2: {
        required: requiredIf(function() {
          return this.fieldKeg === "VF" && this.expandFarm;
        })
      },
      kebun: {
        required: requiredIf(function() {
          return this.fieldKeg === "VK";
        })
      },
      h_kebun: {
        required: requiredIf(function() {
          return this.fieldKeg === "VK";
        })
      },
      marketing: {
        required: requiredIf(function() {
          return this.fieldKeg === "MA";
        })
      },
      h_marketing: {
        required: requiredIf(function() {
          return this.fieldKeg === "MA";
        })
      },
      administ: {
        required: requiredIf(function() {
          return this.fieldKeg === "ADM";
        })
      },
      h_administ: {
        required: requiredIf(function() {
          return this.fieldKeg === "ADM";
        })
      }
    },
    dokumentasiFile: {
      required: requiredIf(function() {
        return !this.isEdit;
      })
    }
  },
  apollo: {
    listDailyReport: {
      query: () => Q_LIST_DAILYREPORT,
      variables() {
        return {
          page: this.currPage,
          limit: this.perPage,
          keyword: this.filterInput,
          user_id: [JwtService.getID()],
          region_id: this.getRegionID(),
          start_date: this.filterSelected1,
          end_date: this.filterSelected2,
          dateType: this.filterDateType
        };
      },
      debounce: 500,
      result({ data }) {
        this.dataLaporan = data.listDailyReport.dailys;
        this.totalPages = data.listDailyReport.totalPage;
        this.totalData = data.listDailyReport.total;
      },
      error(err) {
        this.catchError(err);
      },
      watchLoading(isLoading) {
        this.loading = isLoading;
      }
    }
  },
  computed: {},
  methods: {
    resetKeg() {
      this.expandDist = false;
      this.expandRet1 = false;
      this.expandRet2 = false;
      this.expandFarm = false;
    },
    handleFileUploads() {
      this.dokumentasiURL = URL.createObjectURL(this.dokumentasiFile);
      // console.log(this.dokumentasiFile);
    },
    getDay(value) {
      return moment(value).format("DD");
    },
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    setFilterSelectedWeek() {
      this.filterSelected2 = this.lastDayWeek;
      this.setPage();
      this.customFilter = false;
    },
    setFilterSelectedMonth() {
      this.filterSelected2 = this.lastDayMonth;
      this.setPage();
      this.customFilter = false;
    },
    resetFilter() {
      this.filterSelected1 = "";
      this.filterSelected2 = "";
      this.filterDateType = null;
      this.customFilter = false;
      this.filterKegiatan = null;
    },
    resetFilter1() {
      this.filterSelected2 = "";
      this.setPage();
      this.customFilter = false;
    },

    selectAll() {
      this.lapIDs = [];
      if (!this.allSelected) {
        for (let i in this.dataLaporan) {
          this.lapIDs.push(this.dataLaporan[i].id);
        }
      }
    },
    select() {
      this.allSelected = false;
    },
    setValueLikuidasi() {
      this.formTambah.value_lik =
        this.formTambah.harga_retailer * this.formTambah.qty;
    },
    onAdd() {
      this.modalTitle = this.$t("dailyreporting.add");
      this.isEdit = false;
      this.isDetail = false;
      this.isDisabled = false;
      this.fieldKeg = null;
      this.resetForm();
      this.resetKeg();
      // this.formTambah.tanggal = this.$options.filters.formatDate2(new Date());
      this.$bvModal.show("modal-laporan");
    },

    async fetchFarmer() {
      try {
        const result = await this.$apollo.query({
          query: Q_LIST_FARMER,
          variables: {
            page: 1,
            limit: 9999,
            keyword: "",
            tss: [],
            sp: [],
            country_id: parseInt(this.countryID),
            region_id: parseInt(this.regionID),
            province_id: [],
            kabupaten_id: []
          }
        });
        this.dataFarmer = result.data.listPetani.petanis;
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: error,
          width: 400,
          confirmButtonText: "OK"
        });
      }
    },
    async fetchR1() {
      try {
        const result = await this.$apollo.query({
          query: Q_LIST_RETAILER,
          variables: {
            keyword: "",
            type: this.retailerTypeOptions[0],
            user_id: [JwtService.getID()],
            country_id: this.getCountryID(),
            region_id: this.getRegionID(),
            province_id: this.getProvinceID(),
            kabupaten_id: []
          }
        });
        this.dataR1 = result.data.listRetailer.retailers;
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: error,
          width: 400,
          confirmButtonText: "OK"
        });
      }
    },
    async fetchR2() {
      try {
        const result = await this.$apollo.query({
          query: Q_LIST_RETAILER,
          variables: {
            keyword: "",
            type: this.retailerTypeOptions[1],
            user_id: [JwtService.getID()],
            country_id: this.getCountryID(),
            region_id: this.getRegionID(),
            province_id: this.getProvinceID(),
            kabupaten_id: []
          }
        });
        this.dataR2 = result.data.listRetailer.retailers;
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: error,
          width: 400,
          confirmButtonText: "OK"
        });
      }
    },
    async fetchDistributor() {
      try {
        const result = await this.$apollo.query({
          query: Q_LIST_DISTRIBUTOR,
          variables: {
            keyword: "",
            user_id: [JwtService.getID()],
            country_id: this.getCountryID(),
            region_id: this.getRegionID(),
            province_id: [],
            kabupaten_id: []
          }
        });
        result.data.listDistributor.distributors.map(a => {
          this.dataDistributor.push({
            ...a,
            owner: a.owner ? a.owner : "-"
          });
        });
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: error,
          width: 400,
          confirmButtonText: "OK"
        });
      }
    },

    onDetail(data) {
      this.modalTitle = this.$t("dailyreporting.detail");
      this.fieldKeg = data.kegiatan;
      this.isDetail = true;
      this.isEdit = false;
      this.isDisabled = true;
      this.resetForm();
      this.resetKeg();

      this.formTambah.tanggal = this.formatDate(data.tanggal);
      if (data.d1 !== null) {
        this.formTambah.distributor1 = `${data.distributor1.name} (${data.distributor1.owner})`;
        this.formTambah.h_distributor1 = data.hd1;
      }
      if (data.d2 !== null) {
        this.expandDist = true;
        this.formTambah.distributor2 = `${data.distributor2.name} (${data.distributor2.owner})`;
        this.formTambah.h_distributor2 = data.hd2;
      }
      if (data.r1 !== null) {
        this.formTambah.retailer1 = `${data.retailer1.name} (${data.retailer1.owner})`;
        this.formTambah.h_retailer1 = data.hr1;
      }
      if (data.r2 !== null) {
        this.expandRet1 = true;
        this.formTambah.retailer2 = `${data.retailer2.name} (${data.retailer2.owner})`;
        this.formTambah.h_retailer2 = data.hr2;
      }
      if (data.r3 !== null) {
        this.expandRet2 = true;
        this.formTambah.retailer3 = `${data.retailer3.name} (${data.retailer3.owner})`;
        this.formTambah.h_retailer3 = data.hr3;
      }
      if (data.p1 !== null) {
        this.formTambah.farmer1 = data.petani1.name;
        this.formTambah.h_farmer1 = data.hp1;
      }
      if (data.p2 !== null) {
        this.expandFarm = true;
        this.formTambah.farmer2 = data.petani2.name;
        this.formTambah.h_farmer2 = data.hp2;
      }
      this.formTambah.kebun = data.nama_kebun;
      this.formTambah.h_kebun = data.hasil_kebun;
      this.formTambah.marketing = data.nama_marketing;
      this.formTambah.h_marketing = data.hasil_marketing;
      this.formTambah.administ = data.nama_administrasi;
      this.formTambah.h_administ = data.hasil_administrasi;
      this.formTambah.dokumentasi = data.dokumentasi;

      this.$bvModal.show("modal-laporan");
    },
    closeModal() {
      this.$bvModal.hide("modal-laporan");
    },
    resetForm() {
      this.formTambah.tanggal = null;
      this.formTambah.distributor1 = null;
      this.formTambah.h_distributor1 = null;
      this.formTambah.distributor2 = null;
      this.formTambah.h_distributor2 = null;
      this.formTambah.retailer1 = null;
      this.formTambah.h_retailer1 = null;
      this.formTambah.retailer2 = null;
      this.formTambah.h_retailer2 = null;
      this.formTambah.retailer3 = null;
      this.formTambah.h_retailer3 = null;
      this.formTambah.farmer1 = null;
      this.formTambah.h_farmer1 = null;
      this.formTambah.farmer2 = null;
      this.formTambah.h_farmer2 = null;
      this.formTambah.kebun = null;
      this.formTambah.h_kebun = null;
      this.formTambah.marketing = null;
      this.formTambah.h_marketing = null;
      this.formTambah.administ = null;
      this.formTambah.h_administ = null;
      this.formTambah.dokumentasi = null;
      this.$v.$reset();
    },
    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    onEdit(data) {
      this.modalTitle = this.$t("liq_reporting.edit");
      this.isEdit = true;
      this.isDetail = false;
      this.isDisabled = false;
      this.resetForm();
      this.resetKeg();

      this.formTambah.id = data.id;
      this.fieldKeg = data.kegiatan;
      this.formTambah.tanggal = data.tanggal;
      if (data.d1 !== null) {
        this.formTambah.distributor1 = data.distributor1.id;
        this.formTambah.h_distributor1 = data.hd1;
      }
      if (data.d2 !== null) {
        this.expandDist = true;
        this.formTambah.distributor2 = data.distributor2.id;
        this.formTambah.h_distributor2 = data.hd2;
      }
      if (data.r1 !== null) {
        this.formTambah.retailer1 = data.retailer1.id;
        this.formTambah.h_retailer1 = data.hr1;
      }
      if (data.r2 !== null) {
        this.expandRet1 = true;
        this.formTambah.retailer2 = data.retailer2.id;
        this.formTambah.h_retailer2 = data.hr2;
      }
      if (data.r3 !== null) {
        this.expandRet2 = true;
        this.formTambah.retailer3 = data.retailer3.id;
        this.formTambah.h_retailer3 = data.hr3;
      }
      if (data.p1 !== null) {
        this.formTambah.farmer1 = data.petani1.id;
        this.formTambah.h_farmer1 = data.hp1;
      }
      if (data.p2 !== null) {
        this.expandFarm = true;
        this.formTambah.farmer2 = data.petani2.id;
        this.formTambah.h_farmer2 = data.hp2;
      }
      this.formTambah.kebun = data.nama_kebun;
      this.formTambah.h_kebun = data.hasil_kebun;
      this.formTambah.marketing = data.nama_marketing;
      this.formTambah.h_marketing = data.hasil_marketing;
      this.formTambah.administ = data.nama_administrasi;
      this.formTambah.h_administ = data.hasil_administrasi;
      this.formTambah.dokumentasi = data.dokumentasi;
      // console.log(this.formTambah.dokumentasi);
      this.$bvModal.show("modal-laporan");
    },
    async onSubmit(ev) {
      ev.preventDefault();
      this.$v.$touch();
      if (!this.$v.$error) {
        this.loading = true;
        if (this.isEdit) {
          if (this.dokumentasiFile !== null) {
            let formData = new FormData();
            formData.append("tipe", "daily-report");
            formData.append("file", this.dokumentasiFile);
            await axios
              .post(
                `${process.env.VUE_APP_URL_GRAPHQL}/image/upload`,
                formData,
                {
                  headers: {
                    Authorization: "Bearer " + JwtService.getToken(),
                    "Content-Type": "multipart/form-data"
                  }
                }
              )
              .then(response => {
                if (response.data.message === "success") {
                  const data = response.data.data;
                  this.formTambah.dokumentasi = data.replace(
                    "dashboard",
                    "api"
                  );
                }
              });
          }
          // console.log(this.formTambah.tanggal);
          return new Promise((resolve, reject) => {
            const editLaporan = this.$apollo.mutate({
              mutation: M_EDIT_DAILYREPORT,
              variables: {
                id: this.formTambah.id,
                uid: JwtService.getID(),
                region: this.getRegionID()[0],
                tanggal: this.formTambah.tanggal,
                role: this.roleOptions,
                kegiatan: this.fieldKeg,
                d1: this.formTambah.distributor1,
                hd1: this.formTambah.h_distributor1,
                d2: this.formTambah.distributor2,
                hd2: this.formTambah.h_distributor2,
                r1: this.formTambah.retailer1,
                hr1: this.formTambah.h_retailer1,
                r2: this.formTambah.retailer2,
                hr2: this.formTambah.h_retailer2,
                r3: this.formTambah.retailer3,
                hr3: this.formTambah.retailer3,
                p1: this.formTambah.farmer1,
                hp1: this.formTambah.h_farmer1,
                p2: this.formTambah.farmer2,
                hp2: this.formTambah.h_farmer2,
                nk: this.formTambah.kebun,
                hk: this.formTambah.h_kebun,
                nm: this.formTambah.marketing,
                hm: this.formTambah.h_marketing,
                na: this.formTambah.administ,
                ha: this.formTambah.h_administ,
                dokumentasi: this.formTambah.dokumentasi
              }
            });
            if (resolve) {
              resolve(editLaporan);
            } else {
              reject(editLaporan);
            }
          })
            .then(response => {
              if (response) {
                this.$apollo.queries.listDailyReport.refetch();
                this.toastAlert(this.$t("alert.edit"));
                this.loading = false;
                this.closeModal();
              }
            })
            .catch(err => {
              this.$swal.fire({
                icon: "error",
                title: "Error!",
                text: err.message,
                width: 400,
                confirmButtonText: "OK"
              });
              this.loading = false;
            });
        } else {
          let formData = new FormData();
          formData.append("tipe", "daily-report");
          formData.append("file", this.dokumentasiFile);
          axios
            .post(`${process.env.VUE_APP_URL_GRAPHQL}/image/upload`, formData, {
              headers: {
                Authorization: "Bearer " + JwtService.getToken(),
                "Content-Type": "multipart/form-data"
              }
            })
            .then(response => {
              if (response.data.message === "success") {
                const data = response.data.data;
                this.dokumentasiName = data.replace("dashboard", "api");
              }
              // console.log(this.formTambah.tanggal);
              return new Promise((resolve, reject) => {
                const addReport = this.$apollo.mutate({
                  mutation: M_ADD_DAILYREPORT,
                  variables: {
                    id: JwtService.getID(),
                    region: this.getRegionID()[0],
                    tanggal: this.formTambah.tanggal.toLocaleString(),
                    kegiatan: this.fieldKeg,
                    role: this.roleOptions,
                    d1: this.formTambah.distributor1,
                    hd1: this.formTambah.h_distributor1,
                    d2: this.formTambah.distributor2,
                    hd2: this.formTambah.h_distributor2,
                    r1: this.formTambah.retailer1,
                    hr1: this.formTambah.h_retailer1,
                    r2: this.formTambah.retailer2,
                    hr2: this.formTambah.h_retailer2,
                    r3: this.formTambah.retailer3,
                    hr3: this.formTambah.retailer3,
                    p1: this.formTambah.farmer1,
                    hp1: this.formTambah.h_farmer1,
                    p2: this.formTambah.farmer2,
                    hp2: this.formTambah.h_farmer2,
                    nk: this.formTambah.kebun,
                    hk: this.formTambah.h_kebun,
                    nm: this.formTambah.marketing,
                    hm: this.formTambah.h_marketing,
                    na: this.formTambah.administ,
                    ha: this.formTambah.h_administ,
                    dokumentasi: this.dokumentasiName
                  }
                });
                if (resolve) {
                  resolve(addReport);
                } else {
                  reject(addReport);
                }
              })
                .then(response => {
                  if (response) {
                    this.$apollo.queries.listDailyReport.refetch();
                    this.toastAlert(this.$t("alert.add"));
                    this.loading = false;
                    this.closeModal();
                  }
                })
                .catch(err => {
                  this.$swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: err.message,
                    width: 400,
                    confirmButtonText: "OK"
                  });

                  this.loading = false;
                });
            })
            .catch(err => {
              this.$swal.fire({
                icon: "error",
                title: "Error!",
                text: err.message,
                width: 400,
                confirmButtonText: "OK"
              });
              this.loading = false;
            });
        }
      }
    },
    convertKegiatan(data) {
      let Value;
      switch (data) {
        case "VD":
          Value = "Visit Distributor";
          break;
        case "VR1":
          Value = "Visit Retailer 1";
          break;
        case "VR2":
          Value = "Visit Retailer 2";
          break;
        case "VF":
          Value = "Visit Farmer";
          break;
        case "VK":
          Value = "Visit Kebun";
          break;
        case "MA":
          Value = "Marketing Activity";
          break;
        case "ADM":
          Value = "Administration";
          break;
        default:
          Value = "Wrong Activity";
          break;
      }
      return Value;
    },
    async handleExport() {
      this.isLoading = true;
      await Axios.get(process.env.VUE_APP_URL_GRAPHQL + "/daily-report/excel", {
        responseType: "arraybuffer",
        headers: {
          Authorization: "Bearer " + JwtService.getToken(),
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8"
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `dailyreport-${this.dateFr(
            new Date(),
            "DDMMYYYYHHMMss"
          )}.xlsx`;
          link.click();
        })
        .catch(e => {
          alert(e);
        });
      this.isLoading = false;
      this.isLoading = false;
    }
  },
  mounted() {
    const dateNow = new Date(new Date().setHours(0, 0, 0, 0));
    this.firstDayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
    this.lastDayMonth = new Date(
      dateNow.getFullYear(),
      dateNow.getMonth() + 1,
      0
    );

    this.fetchFarmer();
    this.fetchR1();
    this.fetchR2();
    this.fetchDistributor();
  }
};
</script>

<style>
.datepicker {
  background-color: #fff !important;
}

.daily-1 {
  width: 12.5%;
}

.daily-2 {
  width: 12.5%;
}

.daily-3 {
  width: 60%;
}
</style>
